export const TAG_BOOKMARK = "url";
export const TAG_HIGHLIGHT = "highlight";
export const TAG_PRODUCT = "product";
export const TAG_DOMAIN = "domain";
export const TAG_LIST = "list";
export const TAG_LISTSET = "listset";
export const TAG_USER = "profile";
export const TAG_RSS = "rss";
export const TAG_FEED = "feed";
export const TAG_TWITTER = "twitter";
export const TAG_FACEBOOK = "facebook";
export const TAG_YOUTUBE = 'youtube';
export const TAG_LINKEDIN = 'linkedin';
export const TAG_PINTEREST = 'pinterest';
export const TAG_INSTAGRAM = 'instagram';
export const TAG_LIST_CONNECTION = "listconnection"; // Virtual type
export const TAG_LISTGROUP_CONNECTION = "listgroupconnection"; // Virtual type

export const TAG_CONTENT_TAGS = [TAG_BOOKMARK, TAG_HIGHLIGHT, TAG_PRODUCT,  TAG_RSS, TAG_FEED, TAG_TWITTER, TAG_FACEBOOK, TAG_YOUTUBE, TAG_LINKEDIN, TAG_PINTEREST, TAG_INSTAGRAM];
