import React, { useEffect, useState } from "react";
import moment from "moment";
moment.updateLocale('en', {
    relativeTime: {
        future: " %s",
        past: "%s ago",
        s: number=>number + "s ago",
        ss: '%ds ago',
        m: "1m ago",
        mm: "%dm ago",
        h: "1h ago",
        hh: "%dh ago",
        d: "1d ago",
        dd: "%dd ago",
        M: "a month ago",
        MM: "%d months ago",
        y: "a year ago",
        yy: "%d years ago"
    }
});
const LastUpdated = ({lastUpdated}) => {
  const [counter, setCounter] = useState(0);
  useEffect(() => {
    // Set interval to update the component every second
    const intervalId = setInterval(() => {
        setCounter(pre=> {return pre+1})
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      {Boolean(counter) && <span className="ant-statistic-title">Updated {moment(lastUpdated).toNow()}</span>}
    </>
  );
};

export default LastUpdated;