import React, { useMemo } from "react";
import PropTypes from "prop-types";
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
} from "recharts";
import { getDimensionColorByIndex } from "utils/colorUtils";
import { formatNumber } from "services/numberService";
import { DIMENSION_DATE, DIMENSION_TIMESTAMP } from "constants/dimensions";
import moment from "moment";
import { DATE_FORMAT_CHART, DATE_FORMAT_METRIC } from "constants/default";
import { isEmpty } from "lodash";
import { Empty } from "antd";

const Chart = ({ lines, data, xAxisDataKey, height, allowDecimals }) => {
  let formatXAxis =
    xAxisDataKey === DIMENSION_DATE
      ? (tickItem) =>
          moment(tickItem, DATE_FORMAT_METRIC).format(DATE_FORMAT_CHART)
      : undefined;
    if(xAxisDataKey === DIMENSION_TIMESTAMP){
      formatXAxis = (tickItem) =>
      moment(tickItem).format("h:mm A");
    }
  const computedLines = useMemo(() => {
    return lines.map((header, index) => ({
      ...header,
      color: getDimensionColorByIndex(index),
    }));
  }, [lines]);

  if (isEmpty(data)) return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;

  return (
    <ResponsiveContainer height={height}>
      <LineChart
        data={data}
        margin={{
          top: 5,
          right: 16,
          left: 0,
          bottom: 0,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey={xAxisDataKey}
          tickFormatter={formatXAxis}
          angle={-15}
          fontSize={11}
        />
        <YAxis fontSize={11} allowDecimals={allowDecimals} />
        <Tooltip
          formatter={(value) => formatNumber(value)}
          labelFormatter={formatXAxis}
        />
        <Legend iconType="circle" iconSize={10} />

        {computedLines.map((line) => (
          <Line
            name={line.name}
            key={line.dataKey}
            type="monotone"
            dataKey={line.dataKey}
            stroke={line.color}
            strokeWidth={2}
          />
        ))}
      </LineChart>
    </ResponsiveContainer>
  );
};

Chart.propTypes = {
  data: PropTypes.any,
  height: PropTypes.number,
  lines: PropTypes.array,
  xAxisDataKey: PropTypes.any,
  allowDecimals: PropTypes.bool,
};

Chart.defaultProps = {
  height: 320,
  allowDecimals: true
};

export default Chart;
