import { DATE_FORMAT, DATE_FORMAT_METRIC } from "constants/default";
import {
  DIMENSION_DATE,
  DIMENSION_HOSTNAME,
  DIMENSION_HOSTNAME_FULL,
  EMPTY_DIMENSION_VALUES,
  NOT_SET_DIMENSION_VALUE,
} from "constants/dimensions";
import { forEach, isEmpty, orderBy, replace, toNumber, toLower } from "lodash";
import moment from "moment";
import { fetchAuth } from "utils/API";
import {
  TAG_BOOKMARK,
  TAG_DOMAIN,
  TAG_FACEBOOK,
  TAG_FEED,
  TAG_HIGHLIGHT,
  TAG_LIST,
  TAG_LISTSET,
  TAG_RSS,
  TAG_TWITTER,
} from "constants/contentTags";
import {
  DIMENSION_ITEM_URL,
  DIMENSION_ITEM_URL1,
  DIMENSION_ITEM_URL2,
} from "constants/customDimensions";
import { decompress } from "./textService";

export const getContent = async (id, pk) => {
  let apiUrl = `/api/search/getContentById/${id}`;
  if (pk) {
    apiUrl = `${apiUrl}?pk=${pk}`;
  }
  const { data } = await fetchAuth().get(apiUrl);
  return data;
};

export const getContents = async (ids) => {
  let apiUrl = `/api/search/getContentByIds`;
  const { data } = await fetchAuth().post(apiUrl, ids);
  return data;
};

export const canViewStatistics = async (ids) => {
  let apiUrl = `/api/statistics/canViewStatistics`;
  const { data } = await fetchAuth().post(apiUrl, ids);
  return data;
};

export const getReport = async (request) => {
  const { data } = await fetchAuth().post(`/api/statistics/gaReport`, request);

  return data;
};

export const getBatchReports = async (requests, id, pk) => {
  let apiUrl = `/api/statistics/gaBatchReports`;
  if (pk && id) {
    apiUrl = `${apiUrl}?pk=${pk}&id=${id}`;
  }
  const { data } = await fetchAuth().post(apiUrl, requests);

  return data;
};
export const getBatchRealtimReports = async (requests, id, pk) => {
  let apiUrl = `/api/statistics/gaBatchReports?realtime=1`;
  if (pk && id) {
    apiUrl = `${apiUrl}&pk=${pk}&id=${id}`;
  }
  const { data } = await fetchAuth().post(apiUrl, requests);

  return data;
};

export const getSendInBlueReport = async (requests) => {
  const { data } = await fetchAuth().post(
    `/api/statistics/sibCampaignReport`,
    requests
  );

  return data;
};

export const getPlugiloReport = async (id, pk) => {
  let apiUrl = `/api/statistics/pReport`;
  if (pk) {
    apiUrl = `${apiUrl}?pk=${pk}`;
  }
  const { data } = await fetchAuth().post(apiUrl, {
    id,
  });

  return data;
};

export const getContentNames = async (ids) => {
  const { data } = await fetchAuth().post(`/api/search/getContentNames`, ids);

  return data;
};
export const getInfoboardsByIds = async (ids) => {
  const { data } = await fetchAuth().post(`/api/infoboards/getInfoboardByIds`, {
    ids,
  });

  return data;
};

export const getInfoboards = async (domain) => {
  const { data } = await fetchAuth().post(`/api/infoboards/getInfoboards`, {
    domain: domain,
    excludeEmbedCode: true,
  });

  return data;
};

export const getEmailuploadersByIds = async (dto) => {
  const { data } = await fetchAuth().post(
    `/api/emailuploaders/getSimpleData`,
    dto
  );

  return data;
};

export const getTriggerTexts = async (ids) => {
  const { data } = await fetchAuth().post(`/api/triggertexts/getByIds`, {
    ids,
  });

  return data;
};

export const decompressLinks = async (requestData) => {
  const { data } = await fetchAuth().post(
    `/api/contents/decompressLinks`,
    requestData
  );

  return data;
};

export const getEventDebugger = async (requestData) => {
  const { data } = await fetchAuth().post(
    `/api/statistics/debugger`,
    requestData
  );

  return data;
};

export const reviseReport = (report) => {
  if (!report) return {};

  const { rowCount, rows, dimensionHeaders, metricHeaders } = report;
  return {
    rowCount,
    rows: rows.map((row) => {
      const computedRow = {};
      forEach(dimensionHeaders, (dimensionHeader, index) => {
        if (dimensionHeader.name === DIMENSION_HOSTNAME) {
          computedRow[DIMENSION_HOSTNAME_FULL] =
            row.dimensionValues[index].value;
          computedRow[dimensionHeader.name] = replace(
            row.dimensionValues[index].value,
            "www.",
            ""
          );
        } else
          computedRow[dimensionHeader.name] = row.dimensionValues[index].value;

        if (dimensionHeader.name === DIMENSION_DATE)
          computedRow.displayDate = moment(
            row.dimensionValues[index].value
          ).format(DATE_FORMAT);

        if (row.dimensionValues[index].value === NOT_SET_DIMENSION_VALUE)
          row.dimensionValues[index].value = "Others";
      });

      forEach(metricHeaders, (metricHeader, index) => {
        computedRow[metricHeader.name] = toNumber(
          row.metricValues[index].value
        );
      });
      return computedRow;
    }),
  };
};

export const reviseTimeSeriesData = (data, options, order = "asc") => {
  if (isEmpty(options)) return [];

  const { startDate, endDate, dateKey, dataKeys } = options;

  const momentStartDate = moment(startDate);
  const momentEndDate = moment(endDate);

  const revisedData = [...data];
  const defaultMetricValuesMap = dataKeys.reduce((result, header) => {
    result[header] = 0;
    return result;
  }, {});
  for (
    let m = moment(momentStartDate);
    m.diff(momentEndDate, "days") <= 0;
    m.add(1, "days")
  ) {
    const currentDateAsString = m.format(DATE_FORMAT_METRIC);
    const matchItem = revisedData.find(
      (row) => row[dateKey] === currentDateAsString
    );
    if (matchItem) {
      forEach(dataKeys, (header) => {
        if (!matchItem[header]) matchItem[header] = 0;
      });
    } else {
      revisedData.push({
        [dateKey]: currentDateAsString,
        ...defaultMetricValuesMap,
      });
    }
  }

  return orderBy(revisedData, [dateKey], [order]);
};

export const getContentTypeName = (tag) => {
  const contentTypeNamesMap = {
    [TAG_BOOKMARK]: "Card",
    [TAG_DOMAIN]: "Company",
    [TAG_HIGHLIGHT]: "Newsletter",
    [TAG_LIST]: "Sub Stack",
    [TAG_LISTSET]: "Stack",
    [TAG_FACEBOOK]: "Facebook",
    [TAG_TWITTER]: "Twitter",
    [TAG_FEED]: "Feed",
    [TAG_RSS]: "News",
    "plugilo-video": "Plugilo Video",
  };

  return contentTypeNamesMap[tag];
};

export const getRoleByKey = (key) => {
  switch (key) {
    case "button_media_next":
    case "button-media-next":
      return {
        key: "button_media_next",
        name: "Media - Next Button",
      };
    case "button_media_prev":
    case "button-media-prev":
      return {
        key: "button_media_prev",
        name: "Media - Previous Button",
      };
    case "button_card_flip":
    case "button-card-flip":
      return {
        key: "button_card_flip",
        name: "Card - Flip Button",
      };
    case "button_follow":
    case "button-follow":
      return {
        key: "button_follow",
        name: "Card - Follow Button",
      };
    case "button_plug":
    case "button-plug":
      return {
        key: "button_plug",
        name: "Card - Plug Button",
      };
    case "button_card_comment":
    case "button-card-comment":
      return {
        key: "button_card_comment",
        name: "Card - Comment Button",
      };
    case "button_card_more":
    case "button-card-more":
      return {
        key: "button_card_more",
        name: "Card - More Button",
      };
    case "button_card_media":
    case "button-card-media":
      return {
        key: "button_card_media",
        name: "Card - Media button",
      };
    case "button_card_statistics":
    case "button-card-statistics":
      return {
        key: "button_card_statistics",
        name: "Card - Statistics Button",
      };
    case "button_tip_close":
    case "button-tip-close":
      return {
        key: "button_tip_close",
        name: "Tip - Close Button",
      };
    case "button_tip_prev":
    case "button-tip-prev":
      return {
        key: "button_tip_prev",
        name: "Tip - Previous Button",
      };
    case "button_tip_next":
    case "button-tip-next":
      return {
        key: "button_tip_next",
        name: "Tip - Next Button",
      };
    default:
      return {
        key: "others",
        name: "Others",
      };
  }
};

export const getContentSourceName = (tag) => {
  const map = {
    infoboard: "Widget",
    plugilo: "Plugilo Portal",
    email: "Email",
  };

  return map[tag];
};

export const getDecompressedUrl = (record) => {
  if (isEmpty(record)) return "Others";

  const urls = [
    record[DIMENSION_ITEM_URL],
    record[DIMENSION_ITEM_URL1],
    record[DIMENSION_ITEM_URL2],
  ];
  const filteredUrls = urls
    .filter((url) => url && !EMPTY_DIMENSION_VALUES.includes(url))
    .join("");
  return decompress(filteredUrls) || "Others";
};
