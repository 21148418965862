export const formatNumber = (value) => {
  const result = new Intl.NumberFormat("en", {
    maximumFractionDigits: 1,
  }).format(value);
  return isNaN(value) ? value : result;
};

export function formatDuration(seconds) {
  const h = Math.floor(seconds / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = seconds % 60;
  return `${h > 0 ? h + 'h ' : ''}${m > 0 ? m + 'm ' : ''}${s}s`;
}