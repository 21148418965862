const FILTER_LOOKBACK_WINDOW_5_MINUTES = 5;
const FILTER_LOOKBACK_WINDOW_10_MINUTES = 10;
const FILTER_LOOKBACK_WINDOW_30_MINUTES = 30;
const FILTER_LOOKBACK_WINDOW_60_MINUTES = 60;

export {
  FILTER_LOOKBACK_WINDOW_5_MINUTES,
  FILTER_LOOKBACK_WINDOW_10_MINUTES,
  FILTER_LOOKBACK_WINDOW_30_MINUTES,
  FILTER_LOOKBACK_WINDOW_60_MINUTES
};
