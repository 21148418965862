import React, { useMemo, useState } from "react";
import { Button, Space, Timeline, Typography } from "antd";
import moment from "moment";
import PropTypes from "prop-types";
import { render } from "@testing-library/react";
import {
  DIMENSION_DURATION,
  DIMENSION_EVENT_ACTION,
  DIMENSION_ITEM_BRAND,
  DIMENSION_ITEM_CATEGORY,
  DIMENSION_ITEM_ID,
  DIMENSION_ITEM_NAME,
  DIMENSION_ITEM_SOURCE,
  DIMENSION_ITEM_SOURCE_ID,
  DIMENSION_ITEM_SOURCE_NAME,
  DIMENSION_ITEM_SOURCE_STREAM_CATEGORY,
  DIMENSION_ITEM_SOURCE_STREAM_NAME,
} from "constants/customDimensions.piwik";
import {
  EVENT_MAXIMIZE_ITEM,
  EVENT_MINIMIZE_ITEM,
  EVENT_PLUG_ITEM,
  EVENT_SAVE_ITEM,
  EVENT_UNPLUG_ITEM,
  EVENT_VIEW_ITEM_CLICKED,
  EVENT_VIEW_ITEM_DETAILS,
  EVENT_VIEW_ITEM_IMPRESSION,
} from "constants/customEvents";
import {
  TAG_BOOKMARK,
  TAG_FACEBOOK,
  TAG_FEED,
  TAG_HIGHLIGHT,
  TAG_INSTAGRAM,
  TAG_LINKEDIN,
  TAG_LISTSET,
  TAG_PINTEREST,
  TAG_PRODUCT,
  TAG_RSS,
  TAG_TWITTER,
  TAG_YOUTUBE,
} from "constants/contentTags";
import { formatDuration } from "services/numberService";
import { SOURCE_TRIGGER_TEXT } from "constants/contentSources";

const DefaultEventNode = ({ event }) => (
  <>
    <p>
      <Typography.Text strong>{event.event_type[1]}</Typography.Text>
    </p>
    <p>
      <Typography.Text type="secondary">Page Title:</Typography.Text>
      <Typography.Text> {event.event_title}</Typography.Text>
    </p>
    <p>
      <Typography.Text type="secondary">Page URL:</Typography.Text>
      <Typography.Text>
        {" "}
        <a href={event.event_url} target="_blank" rel="noreferrer">
          {event.event_url}
        </a>
      </Typography.Text>
    </p>
    {/* <p>{JSON.stringify(event)}</p> */}
  </>
);
const EventNode = ({ event }) => {
  const itemType = useMemo(() => {
    switch (event[DIMENSION_ITEM_CATEGORY]) {
      case TAG_LISTSET:
        return "Stack";
      case TAG_BOOKMARK:
      case TAG_PRODUCT:
      case TAG_HIGHLIGHT:
      case TAG_RSS:
      case TAG_FEED:
      case TAG_TWITTER:
      case TAG_FACEBOOK:
      case TAG_YOUTUBE:
      case TAG_LINKEDIN:
      case TAG_PINTEREST:
      case TAG_INSTAGRAM:
        return "Card";
        // return "Product";
        // return "Highlight";
        // return "News";
      case "infoboard":
        return "widget";
      case "plugit":
        return "plug-it";

      default:
        if(event[DIMENSION_ITEM_SOURCE] === SOURCE_TRIGGER_TEXT) {
          return "Trigger Text";
        }
        return `Unknown(${event[DIMENSION_ITEM_CATEGORY]})`;
    }
  }, [event]);
  console.log(event, event[DIMENSION_ITEM_CATEGORY], itemType);

  const eventName = useMemo(() => {
    switch (event[DIMENSION_EVENT_ACTION]) {
      case EVENT_VIEW_ITEM_IMPRESSION:
        return `${itemType} Impression`;
      case EVENT_VIEW_ITEM_DETAILS:
        return `View ${itemType} Details`;
      case EVENT_VIEW_ITEM_CLICKED:
        return `${itemType} Clicked`;
      case EVENT_SAVE_ITEM:
        return `${itemType} Saved`;
      case EVENT_PLUG_ITEM:
        return `${itemType} Plugged`;
      case EVENT_UNPLUG_ITEM:
        return `${itemType} Unplugged`;
      case EVENT_MINIMIZE_ITEM:
        return `${itemType} Minimized`;
      case EVENT_MAXIMIZE_ITEM:
        return `${itemType} Maximized`;
      default:
        return `Custom Event[${event[DIMENSION_EVENT_ACTION]}]`;
    }
  }, [event, itemType]);

  return (
    <>
      <p>
        <Typography.Text strong>{eventName}</Typography.Text>
      </p>
      {/* <p>
        <Typography.Text type="secondary">Event Action:</Typography.Text>
        <Typography.Text> {event[DIMENSION_EVENT_ACTION]}</Typography.Text>
      </p> */}
      <p>
        <Typography.Text type="secondary">Page Title:</Typography.Text>
        <Typography.Text> {event.event_title}</Typography.Text>
      </p>
      <p>
        <Typography.Text type="secondary">Page URL:</Typography.Text>
        <Typography.Text> {event.event_url}</Typography.Text>
      </p>
      <p className="mt-2">
        <Typography.Text strong>Details</Typography.Text>
      </p>
      <p>
        <Typography.Text type="secondary">Type:</Typography.Text>
        <Typography.Text> {itemType}</Typography.Text>
      </p>
      {!!event[DIMENSION_DURATION] && (
        <p>
          <Typography.Text type="secondary">Duration:</Typography.Text>
          <Typography.Text>
            {" "}
            {formatDuration(
              Math.floor(Number(event[DIMENSION_DURATION]) / 1000)
            )}
          </Typography.Text>
        </p>
      )}
      <p>
        <Typography.Text type="secondary">Item Name:</Typography.Text>
        <Typography.Text> {event[DIMENSION_ITEM_NAME]}</Typography.Text>
      </p>
      {/* <p>
        <Typography.Text type="secondary">Item ID:</Typography.Text>
        <Typography.Text> {event[DIMENSION_ITEM_ID]}</Typography.Text>
      </p> */}
      {!!event[DIMENSION_ITEM_BRAND] && (
        <p>
          <Typography.Text type="secondary">Item Domain:</Typography.Text>
          <Typography.Text> {event[DIMENSION_ITEM_BRAND]}</Typography.Text>
        </p>
      )}
      {event[DIMENSION_ITEM_SOURCE_STREAM_CATEGORY] === TAG_LISTSET && (
        <p>
          <Typography.Text type="secondary">In Stack:</Typography.Text>
          <Typography.Text>
            {" "}
            {event[DIMENSION_ITEM_SOURCE_STREAM_NAME]}
          </Typography.Text>
        </p>
      )}

      <p>
        <Typography.Text type="secondary">Infoboard Name:</Typography.Text>
        <Typography.Text> {event[DIMENSION_ITEM_SOURCE_NAME]}</Typography.Text>
      </p>
      {/* <p>
        <Typography.Text type="secondary">Infoboard ID:</Typography.Text>
        <Typography.Text> {event[DIMENSION_ITEM_SOURCE_ID]}</Typography.Text>
      </p> */}
      {/* <p>{JSON.stringify(event)}</p> */}
    </>
  );
};
const TriggerTextEventNode = ({ event }) => {
  const itemType= 'Trigger Text';

  const eventName = useMemo(() => {
    switch (event[DIMENSION_EVENT_ACTION]) {
      case EVENT_VIEW_ITEM_IMPRESSION:
        return `${itemType} Impression`;
      case EVENT_VIEW_ITEM_DETAILS:
        return `View ${itemType} Details`;
      case EVENT_VIEW_ITEM_CLICKED:
        return `${itemType} Clicked`;
      case EVENT_SAVE_ITEM:
        return `${itemType} Saved`;
      case EVENT_PLUG_ITEM:
        return `${itemType} Plugged`;
      case EVENT_UNPLUG_ITEM:
        return `${itemType} Unplugged`;
      case EVENT_MINIMIZE_ITEM:
        return `${itemType} Minimized`;
      case EVENT_MAXIMIZE_ITEM:
        return `${itemType} Maximized`;
      default:
        return "Custom Event";
    }
  }, [event, itemType]);

  return (
    <>
      <p>
        <Typography.Text strong>{eventName}</Typography.Text>
      </p>
      {/* <p>
        <Typography.Text type="secondary">Event Action:</Typography.Text>
        <Typography.Text> {event[DIMENSION_EVENT_ACTION]}</Typography.Text>
      </p> */}
      <p>
        <Typography.Text type="secondary">Page Title:</Typography.Text>
        <Typography.Text> {event.event_title}</Typography.Text>
      </p>
      <p>
        <Typography.Text type="secondary">Page URL:</Typography.Text>
        <Typography.Text> {event.event_url}</Typography.Text>
      </p>
      <p className="mt-2">
        <Typography.Text strong>Details</Typography.Text>
      </p>
      {/* <p>
        <Typography.Text type="secondary">Type:</Typography.Text>
        <Typography.Text> {itemType}</Typography.Text>
      </p> */}
      {!!event[DIMENSION_DURATION] && (
        <p>
          <Typography.Text type="secondary">Duration:</Typography.Text>
          <Typography.Text>
            {" "}
            {formatDuration(
              Math.floor(Number(event[DIMENSION_DURATION]) / 1000)
            )}
          </Typography.Text>
        </p>
      )}    
          

      <p>
        <Typography.Text type="secondary">Text:</Typography.Text>
        <Typography.Text> {event[DIMENSION_ITEM_SOURCE_NAME]}</Typography.Text>
      </p>
     
      {/* <p>{JSON.stringify(event)}</p> */}
    </>
  );
};

const EventTimeline = ({ events, numberToShow }) => {
  const [visibleCount, setVisibleCount] = useState(numberToShow);
  const canShowMore = useMemo(
    () => events.length > visibleCount,
    [events.length, visibleCount]
  );
  const shouldshowFirstPage = useMemo(
    () => visibleCount > numberToShow,
    [visibleCount, numberToShow]
  );

  const renderEventNode = (event) => {
    if (event.event_type[0] === 5) {
      if(event[DIMENSION_ITEM_SOURCE] === SOURCE_TRIGGER_TEXT) {
        return <TriggerTextEventNode event={event} />;
      }
      return <EventNode event={event} />;
      // custom event
      //   switch (event[DIMENSION_EVENT_ACTION]) {
      //     case EVENT_VIEW_ITEM_IMPRESSION:
      //       return <ImpressionEventNode event={event} />;
      //     case EVENT_VIEW_ITEM_DETAILS:
      //       break;
      //     case EVENT_VIEW_ITEM_CLICKED:
      //       break;
      //     case EVENT_SAVE_ITEM:
      //       break;
      //     case EVENT_PLUG_ITEM:
      //       break;
      //     case EVENT_UNPLUG_ITEM:
      //       break;
      //     case EVENT_MINIMIZE_ITEM:
      //       break;
      //     case EVENT_MAXIMIZE_ITEM:
      //       break;
      //     // case EVENT_OPEN_ITEM:
      //     //   break;
      //     // case EVENT_CLOSE_ITEM:
      //     //   break;
      //     default:
      //       break;
      //   }
    }
    return <DefaultEventNode event={event} />;
  };

  return (
    <div>
      <div>
        <Timeline mode="left">
          {events.slice(0, visibleCount).map((event, index) => (
            <Timeline.Item
              key={index}
              label={moment(event.server_time).format("h:mm A")}
            >
              {renderEventNode(event)}
            </Timeline.Item>
          ))}
        </Timeline>
      </div>
      <div className="justify-center flex">
        <Space>
          {shouldshowFirstPage && (
            <Button size="small" onClick={() => setVisibleCount(numberToShow)}>
              Show first {numberToShow} events
            </Button>
          )}
          {canShowMore && (
            <Button
              size="small"
              onClick={() => setVisibleCount(visibleCount + numberToShow)}
            >
              Show next events
            </Button>
          )}
        </Space>
      </div>
    </div>
  );
};

EventTimeline.propTypes = {
  events: PropTypes.arrayOf(PropTypes.object).isRequired,
  numberToShow: PropTypes.number,
};
EventTimeline.defaultProps = {
  numberToShow: 10,
};

export default EventTimeline;
