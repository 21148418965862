export const DIMENSION_EVENT_ACTION = "custom_event_action";
export const DIMENSION_INFOBOARD_BRAND = "event_custom_dimension_1";
export const DIMENSION_ITEM_BRAND = "event_custom_dimension_2";
export const DIMENSION_ITEM_CATEGORY = "event_custom_dimension_3";
export const DIMENSION_ITEM_CATEGORY2 = "event_custom_dimension_6";
export const DIMENSION_ITEM_ID = "event_custom_dimension_4";
export const DIMENSION_ITEM_NAME = "event_custom_dimension_5";
export const DIMENSION_ITEM_URL = "event_custom_dimension_14";
export const DIMENSION_ITEM_URL1 = "event_custom_dimension_15";
export const DIMENSION_ITEM_URL2 = "event_custom_dimension_16";
export const DIMENSION_ITEM_URL3 = "event_custom_dimension_17";
export const DIMENSION_ITEM_URL4 = "event_custom_dimension_18";
export const DIMENSION_ITEM_SOURCE = "event_custom_dimension_8";
export const DIMENSION_ITEM_SOURCE_ID = "event_custom_dimension_9";
export const DIMENSION_ITEM_SOURCE_NAME = "event_custom_dimension_10";
// export const DIMENSION_CIRCULATION_ID = "customEvent:circulation_id";
export const DIMENSION_ITEM_SOURCE_STREAM_CATEGORY =
  "event_custom_dimension_11";
export const DIMENSION_ITEM_SOURCE_STREAM_ID = "event_custom_dimension_12";
export const DIMENSION_ITEM_SOURCE_STREAM_NAME = "event_custom_dimension_13";
export const DIMENSION_SEARCH_TERM = "event_custom_dimension_20";
export const DIMENSION_ROLE = "event_custom_dimension_19";
export const DIMENSION_DURATION = "event_custom_dimension_21";
