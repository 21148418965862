import React, { useState, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { Select, Space } from "antd";
import {
  FILTER_LOOKBACK_WINDOW_5_MINUTES,
  FILTER_LOOKBACK_WINDOW_10_MINUTES,
  FILTER_LOOKBACK_WINDOW_30_MINUTES,
  FILTER_LOOKBACK_WINDOW_60_MINUTES,
} from "./dateFilterTypes";
import useQueryString from "hooks/useQueryString";
import { get, noop } from "lodash";

const { Option } = Select;

const LookbackWindowFilter = ({ options, onChange }) => {
  const { lookbackWindow } = useQueryString();
  const lookbackWindowOptions = useMemo(() => {
    if (!options || options.length === 0) {
      return [
        {
          id: FILTER_LOOKBACK_WINDOW_5_MINUTES,
          name: "5 Min",
        },
        {
          id: FILTER_LOOKBACK_WINDOW_10_MINUTES,
          name: "10 Min",
        },
        {
          id: FILTER_LOOKBACK_WINDOW_30_MINUTES,
          name: "30 Min",
        },
        {
          id: FILTER_LOOKBACK_WINDOW_60_MINUTES,
          name: "60 Min",
        },
      ];
    } else {
      return options.map((item) => ({
        id: item,
        name: `${item} Min`,
      }));
    }
  }, [options]);
  const [lookbackValue, setLookbackValue] = useState(() => {
    if (lookbackWindow) {
      const foundFilterType = lookbackWindowOptions.find(
        (item) => item.id === lookbackWindow
      );
      return foundFilterType?.id || FILTER_LOOKBACK_WINDOW_5_MINUTES;
    }
    return get(lookbackWindowOptions, "[0].id", FILTER_LOOKBACK_WINDOW_5_MINUTES);
  });

  const onDateFilterSelectChange = useCallback(
    (value) => {
      setLookbackValue(value);
      onChange(value);
    },
    [setLookbackValue]
  );

  return (
    <div className="print:hidden flex flex-col justify-end justify-end">
      <Space className="print:hidden">
        <div>
          <Select
            // defaultValue={FILTER_LOOKBACK_WINDOW_5_MINUTES}
            value={lookbackValue}
            className="w-full"
            onChange={onDateFilterSelectChange}
          >
            {lookbackWindowOptions.map((filterType) => (
              <Option value={filterType.id} key={filterType.id}>
                {filterType.name}
              </Option>
            ))}
          </Select>
        </div>
      </Space>
    </div>
  );
};

LookbackWindowFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  disableDateAfter: PropTypes.object,
};

LookbackWindowFilter.defaultProps = {
  disableDateAfter: null,
  onChange: noop,
};

export default LookbackWindowFilter;
