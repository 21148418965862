export const EVENT_VIEW_ITEM_IMPRESSION = "view_item_impression";
export const EVENT_VIEW_ITEM_DETAILS = "view_item_details";
export const EVENT_VIEW_ITEM_EMAIL_DETAILS = "view_item_email_details";
export const EVENT_VIEW_ITEM_EXTERNAL = "view_item_external";
export const EVENT_VIEW_ITEM_CLICKED = "view_item_clicked";
export const EVENT_VIEW_ITEM_LINK_CLICKED = "view_item_link_clicked";
export const EVENT_SAVE_ITEM = "save_item";
export const EVENT_PLUG_ITEM = "plug_item";
export const EVENT_UNPLUG_ITEM = "unplug_item";
export const EVENT_VIEW_SEARCH_RESULTS = "view_search_results";
export const EVENT_SEARCH = "search";
export const EVENT_SEND_ITEM = "send_item";
export const EVENT_MINIMIZE_ITEM = "minimize_item";
export const EVENT_MAXIMIZE_ITEM = "maximize_item";
export const EVENT_OPEN_ITEM = "open_item";
export const EVENT_CLOSE_ITEM = "close_item";


export const EVENT_ALL_EVENTS = [
    EVENT_VIEW_ITEM_IMPRESSION,
    EVENT_VIEW_ITEM_DETAILS,
    EVENT_VIEW_ITEM_EMAIL_DETAILS,
    EVENT_VIEW_ITEM_EXTERNAL,
    EVENT_VIEW_ITEM_CLICKED,
    EVENT_VIEW_ITEM_LINK_CLICKED,
    EVENT_SAVE_ITEM,
    EVENT_PLUG_ITEM,
    EVENT_UNPLUG_ITEM,
    EVENT_VIEW_SEARCH_RESULTS,
    EVENT_SEARCH,
    EVENT_SEND_ITEM,
    EVENT_MINIMIZE_ITEM,
    EVENT_MAXIMIZE_ITEM,
    EVENT_OPEN_ITEM,
    EVENT_CLOSE_ITEM
];