import React, { useMemo } from "react";
import ChartTitle from "components/ChartTitle";
import { Card, Col, Empty, Row, Statistic, Tooltip } from "antd";
import { GRID_GUTTER, LABEL_INACTIVE } from "constants/ui";
import {
  EVENT_CLOSE_ITEM,
  EVENT_MAXIMIZE_ITEM,
  EVENT_MINIMIZE_ITEM,
  EVENT_PLUG_ITEM,
  EVENT_UNPLUG_ITEM,
  EVENT_VIEW_ITEM_CLICKED,
  EVENT_VIEW_ITEM_DETAILS,
  EVENT_VIEW_ITEM_IMPRESSION,
} from "constants/customEvents";
import {
  METRIC_EVENT_COUNT,
  METRIC_PAGE_VIEWS,
  METRIC_TOTAL_USERS,
} from "constants/metrics";
import Scorecard from "components/charts/Scorecard";
import { get, isEmpty, sum } from "lodash";
import {
  DIMENSION_DATE,
  DIMENSION_DEVICETYPE,
  DIMENSION_EVENT_NAME,
  DIMENSION_TIMESTAMP,
} from "constants/dimensions";
import { reviseTimeSeriesData } from "services/gaService";
import LineChart from "components/charts/LineChart";
import TooltipText from "components/TooltipText";
import { useWidgetsContext } from "../../PlugitProvider";
import {
  DIMENSION_DURATION,
  DIMENSION_ITEM_CATEGORY,
} from "constants/customDimensions";
import { TAG_CONTENT_TAGS, TAG_LIST, TAG_LISTSET } from "constants/contentTags";
import { SOURCE_PLUGIT } from "constants/contentSources";
import moment from "moment";
import BarChart from "components/charts/BarChart";
import {
  Bar,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip as RechartsTooltip,
  BarChart as AntBarChart,
} from "recharts";
import { getDimensionColorByIndex } from "utils/colorUtils";

const OverviewSection = ({
  domain,
  lookbackWindow,
  OverviewReportData,
  contentViewsReportData,
  plugitOverviewReportData,
  visitorOvertimeReportData,
  deviceTypesReportData,
}) => {
  const visitorsOvertimeTimeSeriesData = useMemo(() => {
    if (isEmpty(visitorOvertimeReportData?.rows)) return [];
    // let series = visitorOvertimeReportData.rows.map((row) => ({
    //   [DIMENSION_TIMESTAMP]: new Date(row[DIMENSION_TIMESTAMP]),
    //   [METRIC_TOTAL_USERS]: row[METRIC_TOTAL_USERS],
    // }));
    let data = visitorOvertimeReportData.rows.reduce((accumulate, current) => {
      accumulate[current[DIMENSION_TIMESTAMP]] = current[METRIC_TOTAL_USERS];
      return accumulate;
    }, {});
    const now = moment();
    let series = [];
    for (let i = 0; i < lookbackWindow; i++) {
      const minute = now
        .clone()
        .subtract(lookbackWindow - i, "minute")
        .format("YYYY-MM-DDTHH:mm:00");
      if (data[minute]) {
        series.push({
          [DIMENSION_TIMESTAMP]: minute,
          [METRIC_TOTAL_USERS]: data[minute],
        });
      } else {
        series.push({
          [DIMENSION_TIMESTAMP]: minute,
          [METRIC_TOTAL_USERS]: 0,
        });
      }
    }
    while (series.length < 3) {
      const start = series[0][DIMENSION_TIMESTAMP];
      series = [
        {
          [DIMENSION_TIMESTAMP]: moment(start).subtract(1, "minute").toDate(),
          [METRIC_TOTAL_USERS]: 0,
        },
        ...series,
      ];
    }
    return series;
  }, [lookbackWindow, visitorOvertimeReportData]);

  const deviceTypesSeriesReportData = useMemo(() => {
    if (isEmpty(deviceTypesReportData?.rows)) return [];
    return deviceTypesReportData.rows.map((row) => ({
      [DIMENSION_DEVICETYPE]: row[DIMENSION_DEVICETYPE][1],
      [METRIC_TOTAL_USERS]: row[METRIC_TOTAL_USERS],
    }));
  }, [deviceTypesReportData]);
  // const deviceTypesSeriesReportData = [
  //   { [DIMENSION_DEVICETYPE]: "Desktop", [METRIC_TOTAL_USERS]: 1200 },
  //   { [DIMENSION_DEVICETYPE]: "Mobile", [METRIC_TOTAL_USERS]: 800 },
  //   { [DIMENSION_DEVICETYPE]: "Tablet", [METRIC_TOTAL_USERS]: 300 },
  //   { [DIMENSION_DEVICETYPE]: "Smart TV", [METRIC_TOTAL_USERS]: 150 },
  //   { [DIMENSION_DEVICETYPE]: "Wearable", [METRIC_TOTAL_USERS]: 50 },
  //   { [DIMENSION_DEVICETYPE]: "Game Console", [METRIC_TOTAL_USERS]: 100 },
  //   { [DIMENSION_DEVICETYPE]: "E-Reader", [METRIC_TOTAL_USERS]: 20 },
  //   { [DIMENSION_DEVICETYPE]: "Smart Speaker", [METRIC_TOTAL_USERS]: 60 },
  //   { [DIMENSION_DEVICETYPE]: "Feature Phone", [METRIC_TOTAL_USERS]: 10 },
  //   { [DIMENSION_DEVICETYPE]: "Other", [METRIC_TOTAL_USERS]: 5 },
  // ];

  const contentActiveViewCount = useMemo(() => {
    if (isEmpty(contentViewsReportData)) return 0;

    return sum(
      contentViewsReportData.rows
        .filter(
          (row) =>
            row[DIMENSION_EVENT_NAME] === EVENT_VIEW_ITEM_DETAILS &&
            TAG_CONTENT_TAGS.indexOf(row[DIMENSION_ITEM_CATEGORY]) > -1
        )
        .map((row) => row[METRIC_EVENT_COUNT])
    );
  }, [contentViewsReportData]);

  const dockImpressionCount = useMemo(() => {
    if (isEmpty(contentViewsReportData)) return 0;

    return sum(
      contentViewsReportData.rows
        .filter(
          (row) =>
            row[DIMENSION_EVENT_NAME] === EVENT_VIEW_ITEM_IMPRESSION &&
            [SOURCE_PLUGIT].indexOf(row[DIMENSION_ITEM_CATEGORY]) > -1
        )
        .map((row) => row[METRIC_EVENT_COUNT])
    );
  }, [contentViewsReportData]);
  const dockOpenedCount = useMemo(() => {
    if (isEmpty(contentViewsReportData)) return 0;
    return sum(
      contentViewsReportData.rows
        .filter(
          (row) =>
            row[DIMENSION_EVENT_NAME] === EVENT_MAXIMIZE_ITEM &&
            [SOURCE_PLUGIT].indexOf(row[DIMENSION_ITEM_CATEGORY]) > -1
        )
        .map((row) => row[METRIC_EVENT_COUNT])
    );
  }, [contentViewsReportData]);

  const dockAvgOpenTime = useMemo(() => {
    if (isEmpty(plugitOverviewReportData)) return 0;
    const durations = plugitOverviewReportData.rows
      .filter((row) => row[DIMENSION_EVENT_NAME] === EVENT_MINIMIZE_ITEM)
      .map((row) => parseInt(row[DIMENSION_DURATION]))
      .filter((duration) => !isNaN(duration));
    return Math.ceil(sum(durations) / durations.length);
  }, [plugitOverviewReportData]);

  const contentImpressionCount = useMemo(() => {
    if (isEmpty(contentViewsReportData)) return 0;

    return sum(
      contentViewsReportData.rows
        .filter(
          (row) =>
            row[DIMENSION_EVENT_NAME] === EVENT_VIEW_ITEM_IMPRESSION &&
            TAG_CONTENT_TAGS.indexOf(row[DIMENSION_ITEM_CATEGORY]) > -1
        )
        .map((row) => row[METRIC_EVENT_COUNT])
    );
  }, [contentViewsReportData]);

  const contentClickCount = useMemo(() => {
    if (isEmpty(contentViewsReportData)) return 0;

    return sum(
      contentViewsReportData.rows
        .filter(
          (row) =>
            row[DIMENSION_EVENT_NAME] === EVENT_VIEW_ITEM_CLICKED &&
            TAG_CONTENT_TAGS.indexOf(row[DIMENSION_ITEM_CATEGORY]) > -1
        )
        .map((row) => row[METRIC_EVENT_COUNT])
    );
  }, [contentViewsReportData]);

  const stackImpressionCount = useMemo(() => {
    if (isEmpty(contentViewsReportData)) return 0;

    return sum(
      contentViewsReportData.rows
        .filter(
          (row) =>
            row[DIMENSION_EVENT_NAME] === EVENT_VIEW_ITEM_IMPRESSION &&
            [TAG_LISTSET, TAG_LIST].indexOf(row[DIMENSION_ITEM_CATEGORY]) > -1
        )
        .map((row) => row[METRIC_EVENT_COUNT])
    );
  }, [contentViewsReportData]);

  const stackViewCount = useMemo(() => {
    if (isEmpty(contentViewsReportData)) return 0;

    return sum(
      contentViewsReportData.rows
        .filter(
          (row) =>
            row[DIMENSION_EVENT_NAME] === EVENT_VIEW_ITEM_DETAILS &&
            [TAG_LISTSET, TAG_LIST].indexOf(row[DIMENSION_ITEM_CATEGORY]) > -1
        )
        .map((row) => row[METRIC_EVENT_COUNT])
    );
  }, [contentViewsReportData]);

  const contentAddedCount = useMemo(() => {
    if (isEmpty(contentViewsReportData)) return 0;

    return sum(
      contentViewsReportData.rows
        .filter(
          (row) =>
            row[DIMENSION_EVENT_NAME] === EVENT_PLUG_ITEM &&
            TAG_CONTENT_TAGS.indexOf(row[DIMENSION_ITEM_CATEGORY]) > -1
        )
        .map((row) => row[METRIC_EVENT_COUNT])
    );
  }, [contentViewsReportData]);

  const contentRemovedCount = useMemo(() => {
    if (isEmpty(contentViewsReportData)) return 0;

    return sum(
      contentViewsReportData.rows
        .filter(
          (row) =>
            row[DIMENSION_EVENT_NAME] === EVENT_UNPLUG_ITEM &&
            TAG_CONTENT_TAGS.indexOf(row[DIMENSION_ITEM_CATEGORY]) > -1
        )
        .map((row) => row[METRIC_EVENT_COUNT])
    );
  }, [contentViewsReportData]);

  const totalUsers = useMemo(() => {
    if (isEmpty(OverviewReportData)) return 0;

    return sum(OverviewReportData.rows.map((row) => row[METRIC_TOTAL_USERS]));
  }, [OverviewReportData]);

  const totalPageViews = useMemo(() => {
    if (isEmpty(OverviewReportData)) return 0;

    return sum(OverviewReportData.rows.map((row) => row[METRIC_PAGE_VIEWS]));
  }, [OverviewReportData]);

  return (
    <>
      <Row gutter={GRID_GUTTER}>
        <Col xs={24} lg={12} className="mb-8">
          <ChartTitle>Visitors over time</ChartTitle>
          <Card size="small mb-8">
            <LineChart
              xAxisDataKey={DIMENSION_TIMESTAMP}
              data={visitorsOvertimeTimeSeriesData}
              lines={[
                {
                  name: "Visitors",
                  dataKey: METRIC_TOTAL_USERS,
                },
              ]}
              allowDecimals={false}
            />
          </Card>
          <Row gutter={GRID_GUTTER}>
            <Col span={8}>
              <Row gutter={GRID_GUTTER}>
                <Col span={24}>
                  <Card className="mb-8" size="small">
                    <Row>
                      <Col className="mb-8 lg:mb-0">
                        <Statistic
                          title="Total Visitors"
                          value={totalUsers || LABEL_INACTIVE}
                        />
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
              <Row gutter={GRID_GUTTER}>
                <Col span={24}>
                  <Card size="small">
                    <Row>
                      <Col className="mb-8 lg:mb-0">
                        <Statistic
                          title="Page Views"
                          value={totalPageViews || LABEL_INACTIVE}
                        />
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col span={16}>
              {/* <ChartTitle>Devices</ChartTitle> */}
              <Card size="small">
                {isEmpty(deviceTypesSeriesReportData) ? (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    style={{ height: 120 }}
                  />
                ) : (
                  <ResponsiveContainer height={185}>
                    <AntBarChart
                      data={deviceTypesSeriesReportData}
                      margin={{
                        top: 0,
                        right: 16,
                        left: 0,
                        bottom: 0,
                      }}
                      barCategoryGap="10%"
                      maxBarSize={120}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey={DIMENSION_DEVICETYPE} />
                      <YAxis allowDecimals={false} />
                      <RechartsTooltip />
                      <Legend iconType="circle" iconSize={10} />
                      {[
                        {
                          name: "Visitors",
                          dataKey: METRIC_TOTAL_USERS,
                          color: getDimensionColorByIndex(0),
                        },
                      ].map((bar) => (
                        <Bar
                          name={bar.name}
                          key={bar.dataKey}
                          dataKey={bar.dataKey}
                          fill={bar.color}
                        />
                      ))}
                    </AntBarChart>
                  </ResponsiveContainer>
                )}
              </Card>
            </Col>
          </Row>
        </Col>
        <Col xs={24} lg={12}>
          {/* <ChartTitle>Vistors by City</ChartTitle>
          <Card size="small mb-8">
            <LineChart
              xAxisDataKey="date"
              data={[] || "widgetImpressionWithUsersTrendTimeSeriesData"}
              lines={[
                {
                  name: "Ad Impressions",
                  dataKey: EVENT_VIEW_ITEM_IMPRESSION,
                },

                {
                  name: "Visitors",
                  dataKey: METRIC_TOTAL_USERS,
                },
              ]}
            />
          </Card> */}

          <ChartTitle>Plugit Dock</ChartTitle>
          <Card className="mb-8" size="small">
            <Row>
              <Col span={8} lg={8} className="mb-8 lg:mb-0">
                <Statistic
                  title="Impressions"
                  value={dockImpressionCount || LABEL_INACTIVE}
                />
              </Col>

              <Col span={8} lg={8} className="mb-8 lg:mb-0">
                <Statistic
                  title="Open"
                  value={dockOpenedCount || LABEL_INACTIVE}
                />
              </Col>
              <Col span={8} lg={8} className="mb-8 lg:mb-0">
                <Statistic
                  title="Avg. Time Open"
                  value={dockAvgOpenTime || LABEL_INACTIVE}
                  suffix="ms"
                />
              </Col>
            </Row>
          </Card>

          <ChartTitle>Content</ChartTitle>
          <Row gutter={GRID_GUTTER} className="mb-8">
            <Col span={8} lg={8} className="mb-8 lg:mb-0">
              <Card size="small">
                <Statistic
                  title="Impressions"
                  value={contentImpressionCount || LABEL_INACTIVE}
                />
              </Card>
            </Col>

            <Col span={8} lg={8} className="mb-8 lg:mb-0">
              <Card size="small">
                <Statistic
                  title="Views"
                  value={contentActiveViewCount || LABEL_INACTIVE}
                />
              </Card>
            </Col>
            <Col span={8} lg={8} className="mb-8 lg:mb-0">
              <Card size="small">
                <Statistic
                  title="Clicks"
                  value={contentClickCount || LABEL_INACTIVE}
                />
              </Card>
            </Col>
          </Row>
          <ChartTitle>Stack</ChartTitle>
          <Row gutter={GRID_GUTTER} className="mb-8">
            <Col span={12} lg={12} className="mb-8 lg:mb-0">
              <Card size="small">
                <Statistic
                  title="Impressions"
                  value={stackImpressionCount || LABEL_INACTIVE}
                />
              </Card>
            </Col>

            <Col span={12} lg={12} className="mb-8 lg:mb-0">
              <Card size="small">
                <Statistic
                  title="Views"
                  value={stackViewCount || LABEL_INACTIVE}
                />
              </Card>
            </Col>
          </Row>
          <Row gutter={GRID_GUTTER}>
            <Col span={12} lg={12} className="mb-8 lg:mb-0">
              <Card size="small">
                <Statistic
                  title="Content Added"
                  value={contentAddedCount || LABEL_INACTIVE}
                />
              </Card>
            </Col>

            <Col span={12} lg={12} className="mb-8 lg:mb-0">
              <Card size="small">
                <Statistic
                  title="Content Removed"
                  value={contentRemovedCount || LABEL_INACTIVE}
                />
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row gutter={GRID_GUTTER}>
        <Col xs={24} lg={24} className="mb-8 lg:mb-0"></Col>

        <Col xs={24} lg={12}></Col>
      </Row>
    </>
  );
};

OverviewSection.propTypes = {};

export default OverviewSection;
