import PropTypes from "prop-types";
import React, { useContext } from "react";

const PlugitContext = React.createContext({
  clicksEnabled: false,
  adImpressionsEnabled: true,
});
PlugitContext.displayName = "WidgetsContext";

export const useWidgetsContext = () => useContext(PlugitContext);

export default function PlugitProvider({ children, value }) {
  return (
    <PlugitContext.Provider value={value}>{children}</PlugitContext.Provider>
  );
}

PlugitProvider.propTypes = {
  children: PropTypes.any,
  value: PropTypes.object,
};
